//****************************************************************************
//   Company:: コイト電工株式会社
//     Group:: 鉄道技術部  システムグループ
//----------------------------------------------------------------------------
//  Customer:: 
//  Carclass:: 
//   Product:: クラウドフロントエンド
//----------------------------------------------------------------------------
//   Outline:: 現在閲覧中画面/処理中編成
//  Workfile:: currentViewSlice.ts
//  Revision:: 
//   Modtime:: 
//    Author:: 
//      Supp:: 
//****************************************************************************

/****************************************************************************/
/*								Include Files						        */
/****************************************************************************/
import { createSlice } from '@reduxjs/toolkit';

/****************************************************************************/
/*	定義値                                          						*/
/****************************************************************************/
export const currentViewSlice = createSlice({
	name: "CurrentView",
	initialState: {
		strCurrentView: "", //現在画面
		strCurrentCarType: "", //選択車系名
		strCurrentCarCode: "", //選択車系コード
		strCurrentCarNo: "", //選択車番
		strCurrentCarFormDisp: "", //編成表示名		
		strCurrentCarTypeNo: "", //選択車系_車番
		strCurrentCarData: [], //選択車両データ (ByteArray)
		strCurrentCarConf: "", //選択車両Confデータ (JSON)
		strCurrentCarTrouble: "", //故障一覧
		strCurrentCarTroubleArray: [], //故障一覧
		strCurrentCarTable: "", //選択車系 データ割り付け(JSON)
		strCurrentSelectedItemList: [], //選択車系 表示項目一覧)
		strCurrentSelectedItemListBkp: [], //選択車系 表示項目一覧バックアップ(キャンセル時適用)
		strCurrentTreeItemList: [], //TreeViewItemセット
		strUserDataSetDefault: [], //ユーザデータセット
		strUserDataSetByDnDGrid: [{}], //ユーザデータセット AppItemProperetyGridよりセット [{}]がキー
		strChartDataKey: {}, //NewTabで参照するデータキー
		strCurrentSelectedItemCount: 0, //選択済項目数
		//任意項目抽出
		strPickedSelectedItemRow: 0, //任意項目選択 行数
		strPickedSelectedItemList: [], //任意項目選択 選択項目一覧)
		strPickedSelectedItemListBkp: [], //任意項目選択 表示項目一覧バックアップ(キャンセル時適用)
		strPickedSelectedItemCount: 0, //任意項目選択数
		strPickedSelectedItemPlotData: 0, //任意項目表示用データ
		strPickedSelectedItemPlotXAxis: [], //任意項目表示用データX軸 元データ
	},
	reducers: {
		setCurrentView: (state, action) => {
			state.strCurrentView = action.payload;
		},
		setCurrentCarType: (state, action) => {
			state.strCurrentCarType = action.payload;
		},
		setCurrentCarCode: (state, action) => {
			state.strCurrentCarCode = action.payload;
		},
		setCurrentCarNo: (state, action) => {
			state.strCurrentCarNo = action.payload;
		},
		setCurrentCarFormDisp: (state, action) => {
			state.strCurrentCarFormDisp =  action.payload;
		},	
		setCurrentCarTypeNo: (state, action) => {
			state.strCurrentCarTypeNo = action.payload;
		},
		setCurrentCarData: (state, action) => {
			state.strCurrentCarData = action.payload;
		},
		setCurrentCarTrouble: (state, action) => {
			state.strCurrentCarTrouble = action.payload;
		},
		setCurrentCarTroubleArray: (state, action) => {
			state.strCurrentCarTroubleArray = action.payload;
		},
		setCurrentCarConf: (state, action) => {
			state.strCurrentCarConf = action.payload;
		},
		setCurrentCarTable: (state, action) => {
			state.strCurrentCarTable = action.payload;
		},
		setCurrentSelectedItemList: (state, action) => {
			state.strCurrentSelectedItemList = action.payload;
		},
		setCurrentSelectedItemListBkp: (state, action) => {
			state.strCurrentSelectedItemListBkp = action.payload;
		},
		setCurrentTreeItemList: (state, action) => {
			state.strCurrentTreeItemList = action.payload;
		},
		setUserDataSetDefault: (state, action) => {
			state.strUserDataSetDefault = action.payload;
		},
		setUserDataSetByDnDGrid: (state, action) => {
			state.strUserDataSetByDnDGrid = action.payload;
		},
		setUserDataSetParam: (state, action) => {
			const id = action.payload.param1.split("_")[0];
			const updatedData = state.strUserDataSetByDnDGrid.map((item, index) => {
				if (index.toString() === id) {
					// インデックスを文字列に変換して比較
					// if (item.id === id) {
					switch (action.payload.type) {
						case "ACTION_TYPE_LINE":
							return {
								...item,
								linetype: action.payload.param2
							};
						case "ACTION_TYPE_WIDTH":
							return {
								...item,
								linewidth: action.payload.param2
							};
						case "ACTION_TYPE_COLOR":
							return {
								...item,
								color: action.payload.param2
							};
						default:
							return item;
					}
				}
				return item;
			});

			return {
				...state,
				strUserDataSetByDnDGrid: updatedData
			};
		},

		setChartDataKey: (state, action) => {
			state.strChartDataKey = action.payload;
		},
		setCurrentSelectedItemCount: (state, action) => {
			state.strCurrentSelectedItemCount = action.payload;
		},
		setPickedSelectedItemRow: (state, action) => {
			state.strPickedSelectedItemRow = action.payload;
		},
		setPickedSelectedItemList: (state, action) => {
			state.strPickedSelectedItemList = action.payload;
		},
		setPickedSelectedItemListBkp: (state, action) => {
			state.strPickedSelectedItemListBkp = action.payload;
		},
		setPickedSelectedItemCount: (state, action) => {
			state.strPickedSelectedItemCount = action.payload;
		},
		setPickedSelectedItemPlotData: (state, action) => {
			state.strPickedSelectedItemPlotData = action.payload;
		},
		setPickedSelectedItemPlotXAxis: (state, action) => {
			state.strPickedSelectedItemPlotXAxis = action.payload;
		}
	}
});

export const { setCurrentView } = currentViewSlice.actions;
export const { setCurrentCarType } = currentViewSlice.actions;
export const { setCurrentCarNo } = currentViewSlice.actions;
export const { setCurrentCarCode } = currentViewSlice.actions;
export const { setCurrentCarTypeNo } = currentViewSlice.actions;
export const { setCurrentCarFormDisp } = currentViewSlice.actions;
export const { setCurrentCarData } = currentViewSlice.actions;
export const { setCurrentCarTrouble } = currentViewSlice.actions;
export const { setCurrentCarTroubleArray } = currentViewSlice.actions;
export const { setCurrentCarConf } = currentViewSlice.actions;
export const { setCurrentCarTable } = currentViewSlice.actions;
export const { setCurrentSelectedItemList } = currentViewSlice.actions;
export const { setCurrentSelectedItemListBkp } = currentViewSlice.actions;
export const { setUserDataSetByDnDGrid } = currentViewSlice.actions;
export const { setUserDataSetParam } = currentViewSlice.actions;
export const { setUserDataSetDefault } = currentViewSlice.actions;
export const { setCurrentTreeItemList } = currentViewSlice.actions;
export const { setChartDataKey } = currentViewSlice.actions;
export const { setPickedSelectedItemRow } = currentViewSlice.actions;
export const { setCurrentSelectedItemCount } = currentViewSlice.actions;
export const { setPickedSelectedItemList } = currentViewSlice.actions;
export const { setPickedSelectedItemListBkp } = currentViewSlice.actions;
export const { setPickedSelectedItemCount } = currentViewSlice.actions;
export const { setPickedSelectedItemPlotData } = currentViewSlice.actions;
export const { setPickedSelectedItemPlotXAxis } = currentViewSlice.actions;

export default currentViewSlice.reducer;
